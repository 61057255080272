import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/homeView.vue')
  },
  {
    path:'/login',
    name:'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/loginView.vue')
  },
  {
    path:'/register',
    name:'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/registerView.vue')
  },
  {
    path:'/resend',
    name:'resend',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/resendView.vue')
  },
  {
    path:'/reset-password',
    name:'reset-password',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/resetPasswordView.vue')
  },
  {
    path:'/profil',
    name:'profil',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/profilView.vue')
  },
  {
    path:'/profil/editpassword',
    name:'editpassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/editPasswordView.vue')
  },
  {
    path:'/documents/edit',
    name:'editdocuments',
    component: () => import(/* webpackChunkName: "about" */ '../views/documents/editDocumentView.vue')
  },
  {
    path:'/documents',
    name:'documents',
    component: () => import(/* webpackChunkName: "about" */ '../views/documents/documentsView.vue')
  },
  {
    path:'/documents/import',
    name:'importdocuments',
    component: () => import(/* webpackChunkName: "about" */ '../views/documents/importDocumentView.vue')
  },
  {
    path:'/document/:id',
    name:'document',
    component: () => import(/* webpackChunkName: "about" */ '../views/documents/documentView.vue')
  },
   {
    path:'/user/:id',
    name:'user',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/userView.vue')
   }


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
