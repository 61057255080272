<template>
  <div class="main">
    <div class="menu">
      <div class="link_container"><RouterLink to="/documents/edit" id="editDoc" title="créer un nouveau document" @click="handleMobileMenu">Créer un document pdf</RouterLink></div>
      <div class="link_container"><RouterLink to="/documents/import" id="importPdf" title="Importer un pdf" @click="handleMobileMenu">Importer un PDF</RouterLink></div>
      <div class="link_container"><RouterLink to="/documents" id="documents" title="acceder à la liste des documents" @click="handleMobileMenu">Documents</RouterLink></div>
      <div class="separator"></div>
      <div class="link_container"><RouterLink to="/profil" id="profil" title="acceder à votre profil" @click="handleMobileMenu">Profil</RouterLink></div>
      <div class="link_container"><RouterLink to="/login" id="profil" title="acceder à votre profil" @click="handleDisconnect">Déconnexion</RouterLink></div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      handleMobileMenu() {
        this.$store.commit('setMobileHeader');
        this.$emit('closeMenu')
      },
      handleDisconnect(){
        this.$store.commit('logout');
        this.handleMobileMenu()
      }
    }
  }
</script>

<style scoped>
.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
}
.menu a{
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;

}
.menu a:hover{
  color: #228cee;
}

.main{
  width: 100%;
  position: relative;
  overflow: hidden;
 background-color: yellow;
}
.link_container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  padding: 0 20px;
}
.link_container:hover{
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.separator{
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 10px 0;
}
</style>