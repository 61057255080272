import { createStore } from 'vuex'

export default createStore({
  state: {
    token: window.localStorage.getItem('token') || null,
    connected: window.localStorage.getItem('token')? true : false,
    profil:{},
    isMobile:false,
    db:null,
    documents:null,
    currentTag:[],
    resultSearch:[],
    mobileHeader:false,
    heightApplication:null,
    widthApplication:null
  },
  getters: {
    isConnected: state => state.connected,
    profil:state=>state.profil,
    token: state => state.token,
    currentTag:state=>state.currentTag,
    mobileHeader:state=>state.mobileHeader,
    heightApplication:state=>state.heightApplication,
    widthApplication:state=>state.widthApplication,
  },
  mutations: {
    setWidthApplication(state,width){
      state.widthApplication=width
    },
    setHeightApplication(state,height){
      state.heightApplication=height
    },
    setMobileHeader(state){
      state.mobileHeader=!state.mobileHeader
    },
    async crashDb(state){
      state.db.deleteDatabase('documents')
    },
    addTag(state,tag){
     state.currentTag.findIndex((element)=>element==tag)==-1?state.currentTag.push(tag):[]
    },
    deleteTag(state,index){
      state.currentTag.splice(index,1)
    },
    async readDB(state){
      const transaction=await state.db.transaction(['documents'], 'readonly')
      const objectStore = await transaction.objectStore('documents')
      const request = await objectStore.getAll()
      request.onsuccess = (event) => {
       state.documents = event.target.result
      }
    },
    async addDB(state,document){
      const transaction=state.db.transaction(['documents'], 'readwrite')
      const objectStore = transaction.objectStore('documents')
      objectStore.put({id:1,document:document})
      const instance=this
      transaction.oncomplete = function() {
        instance.commit('readDB')
      };
    },
    async deleteDB(state){
      const transaction=state.db.transaction(['documents'], 'readwrite')
      const objectStore = transaction.objectStore('documents')
      objectStore.clear()
      this.commit('readDB')
    },
    login(state, token) {
      state.token = token;
      state.connected = true;
      window.localStorage.setItem('token', token);
    },
    logout(state) {
      state.token = null;
      state.connected = false;
      window.localStorage.removeItem('token');
    },
    setMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
   async getProfil(state){
      const response=await fetch('https://apisharpdoc.laurisceresoli.fr/api/params/userInfos',{
        method:'GET',
        headers:{
          'Content-Type': 'application/json',
          'authorization': `Bearer ${state.token}`
        }
    })
    const data=await response.json();
    state.profil=data;
  }
},
  actions: {
    updateDatabase(state,documents){
      this.commit('deleteDB')
      this.commit('addDB',{documents:documents,id:8})
    }
  },
  modules: {
  }
})
