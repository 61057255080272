<template>
  <div class="header">
    <h1 @click="navigateHomePage">Document Konica</h1>
    <div class="nav" v-show="isConnected">
      <RouterLink to="/documents/edit" id="editDoc" title="créer un nouveau document">Créer un document pdf</RouterLink>
      <RouterLink to="/documents/import" id="importPdf" title="Importer un pdf">Importer un PDF</RouterLink>
      <RouterLink to="/documents" id="documents" title="acceder à la liste des documents">Documents</RouterLink>
    </div>
    <div class="profil-nav">
      <button @click="logout">{{valueLogin}}</button>
      <RouterLink to="/profil" id="profil" title="acceder à vôtre profil" v-show="isConnected"><span>{{firstProfilChar}}</span></RouterLink>  
    </div>
    <div class="nav-mobile">
      <svg class="hb" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" stroke="#eee" stroke-width=".6" fill="rgba(0,0,0,0)" stroke-linecap="round" style="cursor: pointer" @click="handleMobileHeader" v-if="userIsConnected">
        <path d="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7">
          <animate dur="0.2s" attributeName="d" values="M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7;M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7" fill="freeze" begin="start.begin" />
          <animate dur="0.2s" attributeName="d" values="M3,3L5,5L7,3M5,5L5,5M3,7L5,5L7,7;M2,3L5,3L8,3M2,5L8,5M2,7L5,7L8,7" fill="freeze" begin="reverse.begin" />
        </path>
        <rect width="10" height="10" stroke="none">
          <animate dur="2s" id="reverse" attributeName="width" begin="click" />
        </rect>
        <rect width="10" height="10" stroke="none">
          <animate dur="0.001s" id="start" attributeName="width" values="10;0" fill="freeze" begin="click" />
          <animate dur="0.001s" attributeName="width" values="0;10" fill="freeze" begin="reverse.begin" />
        </rect>
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      firstProfilChar() {
        return this.$store.getters?.profil?.user?.firstname[0];
      },
      isConnected() {
        return this.$store.getters.isConnected;
      },
      valueLogin() {
        if(this.isConnected)return 'Se déconnecter';
        return 'Se connecter';
      },
      userIsConnected(){
        return this.$store.getters.isConnected
      }
    },
    methods: {
      logout() {
        this.$store.commit('logout');
        this.$router.push('/login');
      },
      navigateHomePage() {
        this.$router.push('/');
      },
      handleMobileHeader() {
        this.$store.commit('setHeightApplication', document.querySelector('#app').offsetHeight);
        this.$store.commit('setMobileHeader');
        
      }
    }

  }
</script>

<style scoped>
.hb {
  width: 40px;
  margin: 0 auto;
  display: block;
}
h1{
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff;
  cursor: pointer;
  user-select: none;
}
#profil{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #807d7d;
  border-radius: 50%;
  text-decoration: none;
  transition: 0.3s ease;
}
#profil span{
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #FFD700; /* Couleur dorée du texte */
  text-shadow: 0 0 10px #FFD700, 0 0 15px #FFD700, 0 0 20px #FFD700;

}
.nav-mobile{
  display: none;
}
.profil-nav{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  grid-column: 3;
}
#profil:hover{
  background-color: #FFD700;

}
#profil:hover  span{
  color: #C0C0C0; /* Couleur argentée du texte (gris clair) */
  text-shadow: 0 0 10px #C0C0C0, 0 0 15px #C0C0C0, 0 0 20px #C0C0C0;
}
.header{
  display: grid;
  grid-template: 80px/280px 1fr 200px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  color: #fff;
}
.nav{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size:clamp(10px,0.9vw,20px) ;
}
.nav a{
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  transition: 0.3s;
  transition: fontSize 0s;
  padding:0 5px;
  vertical-align: middle;

}
.nav a::after{
  content: '';
  display: block;
  width: 0;
  height: 1.5px;
  background: #fff;
  transition: width .3s;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}
.nav a:hover::after{
  width: 100%;
  transition: width .3s;
}
.nav a:visited{
  color: #fff;
  
}
button{
  background-color: #807d7d;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}
button:hover{
  background-color: #FFD700;
}
@media screen and (max-width: 768px){
  .nav{
    display: none;

  }
  .nav-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    grid-column: 3;
  }
  .profil-nav{
    display: none;
  }
  .header{
    grid-template: 80px/auto auto;
  }
}
</style>