<template>
  <main-header></main-header>
  <Transition> <mobile-header v-show="mobileHeader && widthMin" id="mobile_header" @closeMenu="close" :style="mobileHeaderCss"></mobile-header></Transition>   
  <router-view/>

</template>

<script>
import mainHeader from '@/components/interface/header/headerComponent.vue';
import mobileHeader from '@/components/interface/header/mobileHeader.vue'
export default {
  components: {
    mainHeader,
    mobileHeader
  },
  data() {
    return {
      heightOfApplication: window.innerHeight,
      show:true
    }
  },
  async mounted() {
    this.$store.commit('getProfil');
    this.$store.commit('setWidthApplication', window.innerWidth)
    addEventListener('resize', () => {
      this.$store.commit('setWidthApplication', window.innerWidth)
    })
    if (!this.$store.getters.isConnected) this.$router.push('/login');
    if (window.matchMedia("(pointer: coarse)").matches) {
      this.$store.commit('setMobile', true)
    }
      this.initIndexDB()
  },
  watch: {
    appHeight() {
      this.heightOfApplication = this.$store.getters.heightApplication<window.innerHeight?window.innerHeight:this.$store.getters.heightApplication
    }
  },
  computed:{
      mobileHeader(){
        return this.$store.getters.mobileHeader
      },
      widthMin(){
        return this.$store.getters.widthApplication<770
      },
      appHeight(){
        return this.$store.getters.heightApplication
      },
      mobileHeaderCss(){
          return {
            height: `${this.heightOfApplication - 100}px`
          }
      },
      userIsConnected(){
        return this.$store.getters.isConnected
      }

    },
  methods: {
    close(){
      document.getElementById('reverse').beginElement()

    },
    async initIndexDB() {
        const request = window.indexedDB.open('test')
        let db;
      request.onupgradeneeded = async () => {
          db = request.result;
          const store=db.createObjectStore("documents", { keyPath: "id"});
          store.createIndex('document', 'document');
          store.put({id:1, document:JSON.stringify([])})
        }

        request.onsuccess =async (event) => {
          this.$store.state.db = event.target.result;
          await this.$store.commit('readDB');
        }
       
    },
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 360px;
  overflow-x: hidden;

}

.v-enter-active,
.v-leave-active {
  transition: transform 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(100%);
}


#mobile_header{
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;

  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
